<template>
  <div>
    <v-card
      flat
      class="stream-card"
      ref="streamCard"
      @scroll="handleStreamScroll"
    >
      <v-card-text
        v-if="stream.length < 1 && !isStreamLoading"
        class="text-center"
      >
        No data
      </v-card-text>
      <v-card-text v-else-if="isStreamLoading" class="text-center">
        <v-progress-circular indeterminate />
      </v-card-text>
      <v-card-text v-else>
        <stream-item-card
          v-for="streamItem in stream"
          :key="streamItem.uuid"
          :stream-item="streamItem"
          @click.native="openDetails(streamItem)"
        />

        <div v-if="isStreamLoadingMore" class="text-center">
          <v-progress-circular indeterminate />
        </div>
      </v-card-text>
    </v-card>
    <stream-modal ref="streamModal" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StreamItemCard from "@/views/Contentr/views/ContentrProject/components/StreamItemCard";
import StreamModal from "@/views/Contentr/views/ContentrProject/components/StreamModal";

export default {
  name: "StreamCard",
  components: { StreamModal, StreamItemCard },
  computed: {
    ...mapState({
      stream: state => state.contentrProject.stream,
      isStreamLoadingMore: state => state.contentrProject.isStreamLoadingMore,
      isStreamLoading: state => state.contentrProject.isStreamLoading
    })
  },
  methods: {
    ...mapActions({
      loadMore: "contentrProject/loadMore"
    }),
    handleStreamScroll() {
      const isNearBottom =
        this.$refs.streamCard.$el.scrollTop +
          this.$refs.streamCard.$el.clientHeight >=
        this.$refs.streamCard.$el.scrollHeight - 100;

      if (isNearBottom && !this.isStreamLoading) {
        this.loadMore();
      }
    },
    openDetails(streamItem) {
      this.$refs.streamModal.item = streamItem;
      this.$refs.streamModal.dialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.stream-card {
  max-height: 75vh;
  overflow-y: scroll;
}
</style>
