<template>
  <v-card flat :disabled="areWidgetsLoading">
    <v-card-text>
      <v-text-field
        v-model="form.query"
        type="text"
        :label="$t('CONTENTR.QUERY')"
        outlined
      />
      <v-text-field
        v-model="form.excludeQuery"
        type="text"
        :label="$t('CONTENTR.EXCLUDED_QUERY')"
        outlined
      />
      <v-checkbox
        v-model="form.onlyStories"
        class="mt-0"
        :label="$t('CONTENTR.ONLY_STORIES')"
        value="false"
      />
      <v-menu-date-picker
        v-model="form.startDate"
        :label="$t('COMMON.START_DATE')"
        :filled="false"
        outlined
      />
      <v-menu-date-picker
        v-model="form.endDate"
        :label="$t('COMMON.END_DATE')"
        :filled="false"
        outlined
      />
      <v-row>
        <v-spacer />
        <v-col class="text-right">
          <v-btn color="primary" @click="search">{{
            $t("CONTENTR.SUBMIT")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import VMenuDatePicker from "@/components/VMenuDatePicker";
import { contentrProjectFactory } from "@/store/modules/contentrProject";
import { mapActions, mapState } from "vuex";
export default {
  name: "SearchCard",
  components: { VMenuDatePicker },
  data() {
    return {
      form: contentrProjectFactory()
    };
  },
  computed: {
    ...mapState({
      isStreamLoading: state => state.contentrProject.isStreamLoading,
      areWidgetsLoading: state => state.contentrProject.areWidgetsLoading
    })
  },
  mounted() {
    this.form.startDate = this.$moment()
      .subtract(3, "years")
      .format("YYYY-MM-DD");
    this.form.endDate = this.$moment().format("YYYY-MM-DD");
  },
  methods: {
    ...mapActions({
      getData: "contentrProject/getData"
    }),
    search() {
      this.getData({
        contentrProjectId: this.$route.params.id,
        payload: this.form
      });
    }
  }
};
</script>
