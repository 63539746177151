<template>
  <v-card class="mt-0" flat>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            v-model="form.sortBy"
            label="Sort by"
            :items="sortByItems"
            :menu-props="{ bottom: true, offsetY: true }"
            :disabled="disableFiltering"
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="5">
          <v-select
            v-model="form.sort"
            label="Sort"
            :items="sortItems"
            :menu-props="{ bottom: true, offsetY: true }"
            :disabled="disableFiltering"
            outlined
            hide-details
          />
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <v-btn :disabled="disableFiltering" @click="filterStream"
            >Filter</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "StreamFiltersCard",
  data() {
    return {
      form: {
        sort: "desc",
        sortBy: "Date"
      },
      sortItems: [
        {
          text: "Descending / Newest",
          value: "desc"
        },
        {
          text: "Ascending / Oldest",
          value: "asc"
        }
      ],
      sortByItems: [
        {
          text: "Date",
          value: "Date"
        },
        {
          text: "Likes",
          value: "Likes"
        },
        {
          text: "Comments",
          value: "Comments"
        },
        {
          text: "Shares",
          value: "Shares"
        },
        {
          text: "Views",
          value: "Views"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      isStreamLoadingMore: state => state.contentrProject.isStreamLoadingMore,
      isStreamLoading: state => state.contentrProject.isStreamLoading,
      stream: state => state.contentrProject.stream
    }),
    disableFiltering() {
      return (
        this.isStreamLoading ||
        this.isStreamLoadingMore ||
        this.stream.length < 1
      );
    }
  },
  methods: {
    ...mapActions({
      filter: "contentrProject/filter"
    }),
    filterStream() {
      this.filter({ filters: this.form });
    }
  }
};
</script>
