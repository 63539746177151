<template>
  <v-hover v-slot="{ hover }">
    <v-card flat outlined :elevation="hover ? 1 : 0" class="mb-3 stream-item">
      <v-card-text>
        {{ streamItem.Text }}
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "StreamItemCard",
  props: {
    streamItem: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.stream-item {
  cursor: pointer;
}
</style>
