<template>
  <div>
    <div v-if="project !== null">
      <v-card flat>
        <v-card-title>
          <span class="mr-2">{{ project.name }}</span>
          <small>(id: {{ this.$route.params.id }})</small>
        </v-card-title>
      </v-card>
      <v-row>
        <v-col cols="3">
          <search-card />
        </v-col>
        <v-col dense class="pt-2">
          <v-row dense class="mb-2">
            <v-col>
              <stream-filters-card />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <stream-card />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <widgets-card />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SearchCard from "@/views/Contentr/views/ContentrProject/components/SearchCard";
import StreamCard from "@/views/Contentr/views/ContentrProject/components/StreamCard";
import WidgetsCard from "@/views/Contentr/views/ContentrProject/components/WidgetsCard";
import StreamFiltersCard from "@/views/Contentr/views/ContentrProject/components/StreamFiltersCard";

export default {
  name: "ProjectInfo",
  components: { StreamFiltersCard, WidgetsCard, StreamCard, SearchCard },
  data() {
    return {
      project: null,
      isLoading: true
    };
  },
  mounted() {
    this.getProject(this.$route.params.id)
      .then(response => {
        this.project = response.data.project;
      })
      .catch(error => {
        console.log(error);
        this.$notifier.notify({
          message: "Cannot find project",
          color: "error"
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions({
      getProject: "api/contentr/getProject"
    })
  }
};
</script>
