<template>
  <v-card flat outlined class="mb-3">
    <v-data-table
      :headers="headers"
      :items="data"
      :items-per-page="5"
      :search="search"
      class="elevation-0"
    >
      <template v-slot:top>
        <v-card-text>
          <div class="headline">{{ name }}</div>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-text>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "WidgetTable",
  props: {
    data: {
      required: true,
      type: Array
    },
    name: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Content",
          value: "text"
        },
        {
          text: "Count",
          value: "count"
        }
      ];
    }
  }
};
</script>
