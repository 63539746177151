<template>
  <v-card class="widget-card" flat>
    <v-card-text
      v-if="widgets.total < 1 && !areWidgetsLoading"
      class="text-center"
    >
      No data
    </v-card-text>
    <v-card-text v-else-if="areWidgetsLoading" class="text-center">
      <v-progress-circular indeterminate />
    </v-card-text>
    <v-card-text v-else>
      <v-card flat outlined>
        <v-card-text>
          <vue-highcharts :options="chartOptions" :highcharts="Highcharts" />
        </v-card-text>
      </v-card>

      <widget-table :data="widgets.hashtag_unigram" name="Hashtag unigrams" />
      <widget-table :data="widgets.phrase_unigram" name="Phrase unigrams" />
      <widget-table :data="widgets.hashtag_bigram" name="Hashtag bigrams" />
      <widget-table :data="widgets.phrase_bigram" name="Phrase bigrams" />
      <widget-table
        :data="widgets.phrase_bigram_seq"
        name="Phrase bigrams seq"
      />
      <widget-table
        :data="widgets.phrase_trigram_seq"
        name="Phrase trigrams seq"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import WidgetTable from "@/views/Contentr/views/ContentrProject/components/WidgetTable";
import VueHighcharts from "vue2-highcharts";
import Highcharts from "highcharts";

export default {
  name: "WidgetsCard",
  components: { WidgetTable, VueHighcharts },
  data() {
    return {
      Highcharts: Highcharts
    };
  },
  computed: {
    ...mapState({
      widgets: state => state.contentrProject.widgets,
      areWidgetsLoading: state => state.contentrProject.areWidgetsLoading
    }),
    chartOptions() {
      return {
        title: { text: "Content count by date" },
        xAxis: {
          categories: this.widgets.timeline.map(item =>
            this.$moment(item[0]).format("MMM D")
          )
        },
        series: [
          {
            name: "Count",
            data: this.widgets.timeline.map(item => [
              this.$moment(item[0]).format("hh:ss A, MMM D, Y"),
              item[1]
            ])
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.widget-card {
  max-height: 75vh;
  overflow-y: scroll;
}
</style>
