<template>
  <v-container fluid>
    <project-info />
  </v-container>
</template>

<script>
import ProjectInfo from "@/views/Contentr/views/ContentrProject/components/ProjectInfo";
export default {
  name: "ContenterProject",
  components: { ProjectInfo }
};
</script>
