<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span>
            Stream info
          </span>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ subtitle }}
        </v-card-subtitle>
        <v-card-text v-if="item">
          <div>
            <span>Likes: </span>
            <span>{{ item.Likes }}</span>
          </div>
          <div>
            <span>Comments: </span>
            <span>{{ item.Comments }}</span>
          </div>
          <div>
            <span>Shares: </span>
            <span>{{ item.Shares }}</span>
          </div>
          <div>
            <span>Views: </span>
            <span>{{ item.Views }}</span>
          </div>
          <div>
            <span>Sentiment: </span>
            <span>{{ item.Sentiment }}</span>
          </div>
          <div>
            <span>URL: </span>
            <span>
              <a :href="item.Url">{{ item.Url }}</a>
            </span>
          </div>
          <div>
            <span>Author: </span>
            <span>{{ item.Author ? itemAuthor : "empty" }}</span>
          </div>
          <div>
            <span>Gender: </span>
            <span>{{ item.Gender }}</span>
          </div>
          <div>
            <span>Date: </span>
            <span>{{ $moment(item.Date).format("hh:ss A, MMM D, Y") }}</span>
          </div>
          <div>
            <span>Is story: </span>
            <span>{{ parseInt(item.IsStory) ? "yes" : "no" }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "StreamModal",
  data() {
    return {
      dialog: false,
      item: null
    };
  },
  computed: {
    subtitle() {
      if (!this.item) {
        return "";
      }

      if (this.item.Text.split(" ").length <= 6) {
        return this.item.Text;
      }

      return (
        this.item.Text.split(" ")
          .slice(0, 6)
          .join(" ") + " (...)"
      );
    }
  }
};
</script>
